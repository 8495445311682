import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">藝術家創作經驗分享</h2>
          <h1 className="main_title">張耿華</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_3">
              <figure className="figure">
                <img src={require("../../images/blog/post_3_3.jpg")} alt="張耿華"/>
              </figure>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>張耿華分享近年執行的案例，希望作品引起交流討論，能在目前完整的結構機制下，找到更多可以一起前進的空間。</p>
                <p>豪華朗機工是四人團隊，成員擁有雕塑、哲學、美術、音樂、建築、造型、科技藝術等不同背景，創作涉獵了概念、視覺、機械、互動...等方向，被外界稱為跨領域的科技藝術團隊。</p>
                <p>藝術家多以個人創作為主，組成團隊時，必須經過整合、交流、溝通過程，這在藝術界是新的嘗試，但其它領域，如建築業，是行之有年的工作狀態。另一方面，成員各自出身不同領域，他們的創作，較難在短時間、統一視覺風格觀察出脈絡。</p>
                <p>2010年成軍以來，他們與各個國家、不同單位合作進行多元創作，如法國駐村的照顧計畫、台新基金會共感創作計畫、臺電大樓公共藝術案、臺東南方以南大地藝術祭等。過程中，他們拆解彼此的關係，包含團隊之間、公部門、民間單位、環境、民眾等。「關係」，就是豪華朗機工創作脈絡的核心。</p>
                <p>以下介紹近年參與的一些案例，來回應一些公共藝術的議題：</p>


                <h4>2010 照顧計畫</h4>
                <p>這是一個法國駐村計畫，以啄木鳥跟樹的依存交換關係為概念。地點在鄉村廢棄的紡織廠。這裡由英國藝術家Sam Basu買下改造，他的外籍身分與新屬性(藝術)空間，形成紡織廠與鄰里之間一股特殊的氛圍。計畫分兩部分─《照顧 Treignac Project》、《我在法國》。</p>
                <p>對內，用藝術手法協助屋主整理空間，屋主參與影像拍攝作為交換。對外，請合作舞者編早操，紡織廠周邊社區定點演練，吸引周邊鄰居建立對話關係。藝術與關係的交換概念，成為改變社區關係的觸媒，也是公共藝術的可能形式。</p>


                <h4>2011 天氣好不好我們都要飛 Fly ! no matter what</h4>
                <p>公共藝術已出現多種形式，不受限單一實體作品，有即刻性表演、臨時或永久作品、具漫延性的參與、虛/實等多種可能。</p>
                <p>與台新基金會的合作中，他們提出這個共感計畫，由 37200位小學生共同創作，每個孩子自由發揮鳥的飛行姿態與意義，並透過動畫技術，變成同一隻鳥，飛越每個人想去的地方。計畫傳達的訊息感動了許多人，讓它不只是一件藝術作品，更是一個善良的禮物，其力量能漫延影響整個環境。</p>
                <p>過程中，藝術家觀察到幾件事：一是換位思考，對環境的想像或感受，可能因立場不同而改變。思考作品的發生，必須觀察許多面向，找到一個最大公約數。也許建築和藝術可以站在彼此立場，互相思考。二是臺灣家庭現況，無論外配家庭或隔代教養的教育狀況。三是小朋友的創意，會因師生關係受到影響。</p>


                <h4>2010-2016日光域 </h4>
                <p>理想到現實的平衡練習。這件作品從2010年開始發展，回收家庭不要的燈具，重新轉化成藝術作品。從美術館白牆到真實公共空間，過程適應不同地域的環境關係，最後回到臺灣，發展作品適應建築關係、耐候性，並遇到願意接受機械性作品的業主。</p>


                <h4>2016 臺電大樓 太陽之詩</h4>
                <p>關於前人沒做過的嘗試。這件作品是2016年與台電大樓合作的創作，以太陽提供地球能源的關係對應台電供給台灣能源的任務。創作過程常遇到的反應是─沒有人這樣做過！但他們認為，無論藝術家或科學家，都在嘗試前人未知或沒做過的事。與公部門或廠商的合作，像是一個共同成長的過程，製作廠商原本的零件組成完整作品，會改變原本的認知，增進對作品的認同。</p>

                <h4>2016臺電大樓 樹雨霧</h4>
                <p>談到空間公有化的挑戰。作品的概念是希望開放台電大樓與鄰里接壤的長形廣場，讓民眾進入互動。他們將電線桿造型與噴霧系統結合，透過時段性噴霧表演，吸引人群進入並停留。作品開放後，卻遇到民眾反應，希望減少噴霧時段，一是減少小朋友停留時間，二是對噴霧衛生的擔憂。立意良好的公共藝術遇到現實挑戰，業主與藝術家如何一同面對，也是未來的課題。</p>


                <h4>2017 寧夏銀川美術館 雨霾</h4>
                <p>用輸送帶傳送塑料，彷彿再現雨水在大氣中的循環。作品呈現出幾個觀賞層次，包含遠觀到近看的聽覺、視覺變化，同時反映出人類高度使用塑材，造成的環境浩劫。</p>


                <h4>2018 南方以南 </h4>
                <p>藝術處理「公共性」，回饋與環境的關係。2018年，他們受邀台東南迴的大地藝術祭進行創作。尋找台東的環境關係時，從報導看到尼伯特風災後，台東大批荖葉園毀壞，水泥柱與鋼筋的廢棄回收利潤低廉，無人投標處理這批廢棄物。他們由此著手，取出水泥中的鋼筋，形塑出線性山稜，設置在台東大武的沿海山線，回應花東開發議題。無法處理的廢棄物，與看似不合收益比例的作業，透過藝術的轉化，重新賦與價值，讓人反視與環境之間的關係。</p>

                <h4>2018 過境之時</h4>
                <p>科技的手法不是唯一方式。在泰國喀拉比海岸的造船廠，他們透過數位切割、重組，創造出只存在二維世界的影像，進一步運用3D建模，製作，將一艘7.5公尺的船拉長到24公尺，創造出原先不存在真實世界的船，回應策展「不存在的仙境」概念。</p>
                <p>這件作品結合科技思維和傳統技法，創造新舊並存的作品，他們認為，科技藝術可以內化思維，而非僅限視覺的表現。</p>


                <h4>2017 台北世大運 聖火</h4>
                <p>創造精神性象徵，建立榮耀感。世大運是奧運以外，層級最高的國際賽事，他們透過想像創造指標性平台，跟台灣鋼管車合作，從製作到完成，所有參與者共有的榮耀感。</p>


                <h4>2018 臺中花卉博覽會</h4>
                <p>作品實驗性重要嗎？《聲/光/機/神》2018 臺中花卉博覽會的作品，作品概念啟發自開花─植物生長過程中細胞分裂最繁複的狀態。為呈現生命複雜的姿態，科技表演的編寫與控制系統結合，影響了作品的靈魂，需要很好的跨領域合作才能完成。過程中，不同領域的跨語言交換，需要有效的溝通，才能達到最好的成果，有過去案例的經驗累積，才能完成這樣具實驗性的作品。</p>
                <p>藝術家進一步提問，這些具實驗性的作品，如何面對現在的評選機制？何種背景的評審能評選這些作品？其專業性是否正當？或者僅作為行政把關，協助計畫順利推行，還有需要探討的空間。</p>
                <p>最後，他分享Jack medi的話：「藝術不是再現現實，而是創造相同強度的真實」，送給各位，一起想想能為未來再做點什麼事情。 </p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog" className="next_page">
            <div className="next_title">社群擾動</div>
            <div className="next_owner">對話</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
